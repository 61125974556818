<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">FREQUENTLY ASKED QUESTIONS(FAQ)</div>
      <div class="title1">How can I open a case?</div>
      <div class="con">
        <span>Login with your Steam account.
          Click on your profile image on top.Enter your Trade Offer URL and click on "Save". You can find it here: https://steamcommunity.com/privacy#trade_offer_access_url
          Click on "ADD FUNDS" button on top.Choose a case which you want to buy, click on the case and then choose "Open Case".
        </span>
      </div>
      <div class="title1" style="margin-top: 42px;">Where can I find my Steam Trade Offer URL?</div>
      <div class="con">
        <span>Open your steam trade offers page:
          https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url</span>
      </div>
      <div class="title1" style="margin-top: 42px;">I got an error when I am trying to pick up the item: "Sending item can take more time. Please wait for 12 minutes". What does it mean?</div>
      <div class="con">
        <span>
          Do not worry. This is not an error. It warns you that sending a trade offer for that item may take a little longer. Due to the recent limitations of Steam trades, we have to use a difficult system of sending items, because Steam often blocks active accounts. Our bot can deliver skin from 3 to 15 minutes.
        </span>
      </div>
      <div class="title1" style="margin-top: 42px;">Do I need to make a deposit before I can withdraw items?</div>
      <div class="con">
        <span>Yes. You must deposit to your balance at least once to enable withdrawals. Minimum deposit amount is $4.</span>
      </div>
      <div class="title1" style="margin-top: 42px;">I didn't received any trade offers after I pressed on Accept button. What should I do?</div>
      <div class="con">
        <span>
          There are a few possible reasons why we may be unable to send you a trade offer:
          You are not able to use Steam Trades (logged in from the new device, recently reset the password or e-mail, changed the Steam Guard security settings, VAC). You can check it by sending a trade offer to a friend.
          Your Steam Inventory settings (CHECK) is set to "Hidden". You need switch it to "Public", in order to receive trade offers. Check your trade offer url on your Coffeecupprofile and in the Steam settings - they should match.
          Bot have a connection issues. This happens quite often, the problem is on the side of a Steam service. Bot can also be locked, in this case contact Coffeecupsupport and describe the problem in detail, or send email to us.
        </span>
      </div>
      <div class="title1" style="margin-top: 42px;">How can I receive my CS:GO item?</div>
      <div class="con">
        <span>
          Go to your coffeecupbox.com profile page and click on "ACCEPT" button for the item you want withdraw to.You will receive your items via trade offer, which you can find here:
          https://steamcommunity.com/id/me/tradeoffers
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;

  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    padding: 32px;
  }

  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }

  .title1 {
    font-size: 20px;
    color: #c3c3e2;
    padding: 16px 0;
  }

  .con {
    font-size: 18px;
    color: #848492;

    span {
      margin-top: 10px;
      display: inline-block;
    }
  }
}
</style>