<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">About us</div>
      <div class="con">
        <span> It is committed to developing e-sports to build a digital content ecosystem, providing users with more safe entertainment channels, and using a unique transaction model to enable Steam Entertainment to extract users' inventory more quickly and safely, which is loved by many players.</span>
        <br>
        <span>coffeecupbox.com is a comprehensive CSGO jewelry trading brand covering domestic products. On the coffeecupbox.com website, you can instantly obtain more csgo items. The mall has a large number of products that can be retrieved from stock at any time.<br></span>
        <span>Contact information: support@coffeecupbox.com</span><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    // padding-top: 88px;
    // background-color: #2b2c37;
    padding: 32px;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
</style>